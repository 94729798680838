import 'styles/algolia.scss'
import 'styles/block.scss'
import 'styles/globals.scss'
import 'styles/wysiwyg.scss'

import {DPBlock, DPFullNode, DPMetaBlock, DPNode} from 'types/DPTypes'
import {GeneralContext, GeneralData} from 'types/general'
import React, {useEffect} from 'react'

import {AdminTools} from 'layout/admin/AdminTools'
import type {AppProps} from 'next/app'
import Custom500 from 'pages/500'
import {Footer} from 'layout/app/Footer'
import Head from 'next/head'
import {Header} from 'layout/app/Header'
import TagManager from 'react-gtm-module'

export type AppEnvType = 'production' | 'staging' | 'development'

function _app({Component, pageProps, router, ...rest}: AppProps) {
	const detectedLang = router.asPath.substr(1, 2) === 'en' ? 'en' : 'nl'
	//The generals property is used for 404 pages untill https://github.com/vercel/next.js/discussions/18419 is resolved
	const general: GeneralData =
		pageProps.general ||
		(pageProps.generals ? pageProps.generals[detectedLang] : null)

	const node: DPFullNode = pageProps.node
	const meta: DPMetaBlock = node?.meta
	const isHome: boolean = !!(
		(node?.type !== 'gallery' && node?.blocks) ||
		[]
	).find((bl: DPBlock) => bl.type === 'home')
	const lang = pageProps.node?.langcode || detectedLang
	const appEnv: AppEnvType = pageProps.appEnv

	const lightColor = getLightColorcode(pageProps?.node) || '#abd037'
	const darkColor = getDarkColorcode(pageProps?.node) || '#4b711d'

	useEffect(() => {
		document.documentElement.lang = lang
	}, [lang])

	useEffect(() => {
		document.documentElement.style.setProperty('--color-light', lightColor)
		document.documentElement.style.setProperty('--color-dark', darkColor)
	}, [lightColor, darkColor])

	useEffect(() => {
		if (!process.env.NEXT_PUBLIC_GTM_ID) return
		TagManager.initialize({
			gtmId: process.env.NEXT_PUBLIC_GTM_ID
		})
	}, [])

	if (!general) {
		// This should never occur -> render 500 page without header or footer
		return <Custom500 />
	}

	let metaRobotsContent =
		appEnv === 'production' ? 'index, follow' : 'noindex, nofollow'
	if (node && node.type === 'gallery') metaRobotsContent = 'noindex, nofollow'

	return (
		<div className="app" id="app">
			<Head>
				<meta name="viewport" content="initial-scale=1.0, width=device-width" />
				<meta name="language" content={lang} />
				<meta name="theme-color" content="#092413" />
				<meta name="robots" content={metaRobotsContent} />
				{node && (
					<link rel="canonical" href={`https://flandersfood.com${node.url}`} />
				)}
				<link rel="shortcut icon" href="/assets/favicon.png" />
				{(meta || node) && (
					<>
						<title>{meta?.title || node?.title + ` - Flanders' FOOD`}</title>
						<meta property="og:type" content="website" />
						<meta
							property="og:url"
							content={process.env.NEXT_PUBLIC_WEBSITE_URL + node.url}
						/>
						<meta property="og:title" content={meta?.title || node?.title} />
					</>
				)}
				{meta?.meta_description && (
					<>
						<meta name="description" content={meta.meta_description} />
						<meta property="og:description" content={meta.meta_description} />
					</>
				)}
				{meta?.share_image && (
					<>
						<meta property="og:image" content={meta.share_image.src} />
						<meta
							property="og:image:width"
							content={'' + meta.share_image.width}
						/>
						<meta
							property="og:image:height"
							content={'' + meta.share_image.height}
						/>
					</>
				)}
				<link
					rel="stylesheet"
					href="https://cdn.jsdelivr.net/npm/@algolia/autocomplete-theme-classic"
				/>
			</Head>
			<GeneralContext.Provider value={{...general, node: pageProps.node, lang}}>
				{node?.type !== 'gallery' && <Header home={isHome} />}
				<ErrorBoundary>
					<Component {...pageProps.node} />
				</ErrorBoundary>
				{node?.type !== 'gallery' && <Footer />}
				<AdminTools node={pageProps.node} />
			</GeneralContext.Provider>
		</div>
	)
}

class ErrorBoundary extends React.Component<{}, {hasError: boolean}> {
	constructor(props) {
		super(props)
		this.state = {hasError: false}
	}
	static getDerivedStateFromError(error) {
		return {hasError: true}
	}
	componentDidCatch(error, errorInfo) {
		console.error({error, errorInfo})
	}
	render() {
		if (this.state.hasError) return <Custom500 />
		return this.props.children
	}
}

export function getLightColorcode(node?: DPNode) {
	return getDarkColorcode(node)
}

export function getDarkColorcode(node?: DPNode) {
	switch (node?.type) {
		case 'program':
			return node.color?.colorcode
		case 'roadmap':
			return node.program?.color?.colorcode
		case 'concept':
			return node.roadmap?.program?.color?.colorcode
		case 'page':
			return node.color?.colorcode
		case 'event':
			return node.branding?.color?.colorcode
		case 'project':
			return node.program?.color?.colorcode
		default:
			return null
	}
}

export default _app
