import React, {useContext, useState} from 'react'

import {GeneralContext} from 'types/general'
import {Icon} from 'layout/Icon'
import {Link} from 'util/link'
import css from './Languages.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const Languages: React.FC<{
	className?: string
}> = ({className}) => {
	const [isHovering, setIsHovering] = useState<boolean>(false)
	const {node} = useContext(GeneralContext)
	const currentLang = node?.langcode || 'nl'
	const translations = node?.translations || {en: null, nl: null}

	return (
		<div className={styles.languages.with(className)()}>
			<div className={styles.languages.lang.is({hovering: isHovering})()}>
				<span className={styles.languages.lang.text()}>
					{currentLang.toUpperCase()}
				</span>
				<span className={styles.languages.lang.icon()}>
					<Icon icon="chevron_down" />
				</span>
			</div>
			<div className={styles.languages.list()}>
				{Object.keys(translations).map((lang) => {
					if (currentLang === lang) return null
					const url = translations[lang as 'nl' | 'en'] || `/${lang}`

					return (
						<Link
							key={lang}
							to={url}
							target="_self"
							className={styles.languages.list.link()}
							onFocus={() => setIsHovering(true)}
							onBlur={() => setIsHovering(false)}
							onClick={() => setIsHovering(false)}
						>
							{lang.toUpperCase()}
						</Link>
					)
				})}
			</div>
		</div>
	)
}
