import React from 'react'
import {Mod} from 'types/basictypes'
import {Link} from 'util/link'
import {fromModule} from 'util/styler/Styler'
import {Icon} from './Icon'
import css from './Social.module.scss'

const styles = fromModule(css)

export type SocialType = {
	social: Array<SocialLinkType>
	className?: string
	mod?: SocialModType
}

export type SocialLinkType = {
	type: 'facebook' | 'instagram' | 'linkedin' | 'twitter' | 'youtube'
	url: string
	mod?: SocialModType
}

type SocialModType = Mod<'normal' | 'round' | 'light'>

export const Social: React.FC<SocialType> = ({social, className, mod}) => {
	if (!social || social.length === 0) return null

	return (
		<div className={styles.social.with(className).mod(mod)()}>
			<div className={styles.social.row()}>
				{social.map(
					(item) =>
						item?.type &&
						item?.url && (
							<div key={item.type} className={styles.social.row.item()}>
								<SocialLink mod={mod} {...item} />
							</div>
						)
				)}
			</div>
		</div>
	)
}

export const SocialLink: React.FC<SocialLinkType> = ({type, url, mod}) => {
	return (
		<Link
			to={url}
			title={switchLabel(type)}
			data-platform={switchLabel(type)}
			className={styles.link.mod(type).mod(mod)()}
		>
			<Icon icon={type} />
		</Link>
	)
}

function switchLabel(type) {
	switch (type) {
		case 'facebook':
			return 'Facebook'
		case 'instagram':
			return 'Instagram'
		case 'linkedin':
			return 'LinkedIn'
		case 'twitter':
			return 'Twitter'
		case 'youtube':
			return 'YouTube'
	}
}
