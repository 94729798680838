import {DPLink, DPReusableBlockTerm, DPWysiwyg} from 'types/DPTypes'
import React, {useContext} from 'react'

import {Button} from 'layout/Button'
import {Container} from 'layout/Container'
import {Copyright} from './Copyright'
import {GeneralContext} from 'types/general'
import {Link} from 'util/link'
import Logo from 'assets/logo.svg'
import {Trusted} from 'layout/Trusted'
import css from './Footer.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useTranslation} from 'locale/translate'

const styles = fromModule(css)

type FooterCategoryType = {
	title: string
	items: Array<DPLink>
}

export const Footer: React.FC = () => {
	const {footer} = useContext(GeneralContext)

	return (
		<footer className={styles.footer()}>
			<Container mod="small">
				<FooterLogo />
				<div className={styles.footer.row()}>
					<div className={styles.footer.row.col.mod('category')()}>
						<FooterCategory
							title={footer.column_1_title}
							items={footer.column_1_links}
						/>
					</div>
					<div className={styles.footer.row.col.mod('category')()}>
						<FooterCategory
							title={footer.column_2_title}
							items={footer.column_2_links}
						/>
					</div>
					<div className={styles.footer.row.col.mod('nav')()}>
						<FooterNav nav={footer.menu_items} />
					</div>
					<div className={styles.footer.row.col.mod('newsletter')()}>
						<FooterNewsletter
							title={footer.newsletter_title}
							text={footer.newsletter_description}
							block={footer.newsletter_block}
						/>
					</div>
				</div>
			</Container>
			<Copyright
				banner={footer.banner}
				collaboration={footer.banner_description}
				social={[
					{type: 'youtube' as const, url: footer.youtube},
					{type: 'linkedin' as const, url: footer.linkedin},
					{type: 'twitter' as const, url: footer.twitter}
				]}
				text={footer.copyright_description}
				links={footer.footer_links}
			/>
		</footer>
	)
}

const FooterLogo: React.FC = () => {
	const {links} = useContext(GeneralContext)
	const t = useTranslation()

	return (
		<div className={styles.footer_logo()}>
			<Link
				to={links.home}
				title={t.project_name}
				className={styles.footer_logo.link()}
				aria-label={t.homepage.arialabel}
			>
				<Logo />
			</Link>
			<span className={styles.footer_logo.line()} />
		</div>
	)
}

const FooterCategory: React.FC<FooterCategoryType> = ({title, items}) => {
	if (!items || items.length === 0) return null

	return (
		<div className={styles.footer_category()}>
			<h5 className={styles.footer_category.title()}>{title}</h5>
			<div className={styles.footer_category.items()}>
				{items.map((item) => (
					<div key={item.url} className={styles.footer_category.items.item()}>
						<Link
							to={item.url}
							className={styles.footer_category.items.item.link()}
						>
							{item.title}
						</Link>
					</div>
				))}
			</div>
		</div>
	)
}

const FooterNav: React.FC<{nav: Array<DPLink>}> = ({nav}) => {
	if (!nav || nav.length === 0) return null

	return (
		<div className={styles.footer_nav()}>
			{nav.map((link) => (
				<div key={link.url} className={styles.footer_nav.item()}>
					<Link to={link.url} className={styles.footer_nav.item.link()}>
						{link.title}
					</Link>
				</div>
			))}
		</div>
	)
}

const FooterNewsletter: React.FC<{
	title: string
	text?: DPWysiwyg
	block: DPReusableBlockTerm
}> = ({title, text, block}) => {
	const t = useTranslation()
	const {node} = useContext(GeneralContext)
	const newsletterBlock = (node && 'blocks' in node ? node.blocks : []).find(
		(bl) => bl.type === 'reusable' && bl.reusable_block?.key === block?.key
	)
	const newsletterLink = newsletterBlock
		? '#newsletter'
		: `/${node?.langcode || 'nl'}#newsletter`

	return (
		<div className={styles.footer_newsletter()}>
			{title && <h5 className={styles.footer_newsletter.title()}>{title}</h5>}
			{text && (
				<div className={styles.footer_newsletter.text()}>
					<Trusted>{text}</Trusted>
				</div>
			)}
			<Button to={newsletterLink} className={styles.footer_newsletter.button()}>
				{t.newsletter.button}
			</Button>
		</div>
	)
}
