import React, {useEffect, useState} from 'react'
import {DPFullNode} from 'types/DPTypes'
import {useMediaQuery} from 'util/mediaquery'
import {fromModule} from 'util/styler/Styler'
import css from './AdminTools.module.scss'

const styles = fromModule(css)

export const useIsAdmin = () => {
	const [isAdmin, setIsAdmin] = useState(false)

	useEffect(() => {
		if (process.env.NEXT_PUBLIC_APP_ENV !== 'production') {
			setIsAdmin(true)
			return
		}

		if (!localStorage.admin_enabled) return
		if (!localStorage.admin_refreshed) return

		const validUntil =
			parseInt(localStorage.admin_refreshed, 10) + 1000 * 60 * 60 * 24 * 14
		if (Date.now() > validUntil) return

		setIsAdmin(true)
	}, [])
	return isAdmin
}

export const AdminTools: React.FC<{node: DPFullNode}> = ({node}) => {
	const isAdmin = useIsAdmin()
	const isDesktop = useMediaQuery('(min-width: 1200px)')

	if (!node) return null
	if (!isDesktop) return null
	if (!isAdmin) return null

	return (
		<div className={styles.tools()}>
			<a
				href={`${process.env.NEXT_PUBLIC_DRUPAL_URL}/${node.langcode}/node/${node.node_id}/edit`}
				target="_blank"
				rel="noreferrer"
				className={styles.tools.button()}
			>
				Edit
			</a>
			<a
				href={`${process.env.NEXT_PUBLIC_DRUPAL_URL}/api/page?route=/${node.langcode}/node/${node.node_id}`}
				target="_blank"
				rel="noreferrer"
				className={styles.tools.button()}
			>
				JSON
			</a>
			{'sf_object_id' in node && (
				<a
					href={`${process.env.NEXT_PUBLIC_DRUPAL_URL}/nl/taxonomy/term/${node.sf_object_id}/edit`}
					target="_blank"
					rel="noreferrer"
					className={styles.tools.button()}
				>
					SF Drupal
				</a>
			)}
			{'sf_url' in node && (
				<a
					href={node.sf_url}
					target="_blank"
					rel="noreferrer"
					className={styles.tools.button()}
				>
					Salesforce
				</a>
			)}
			<a
				href={`${process.env.NEXT_PUBLIC_DRUPAL_URL}/api/search/index/${node.node_id}`}
				target="_blank"
				rel="noreferrer"
				className={styles.tools.button()}
			>
				Index
			</a>
		</div>
	)
}
