import React, {useContext} from 'react'

import {GeneralContext} from 'types/general'
import {Icon} from './Icon'
import {Link} from 'util/link'
import css from './BasketIcon.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useBasketNumber} from 'blocks/fullwidth/basket/BasketContext'
import {useTranslation} from 'locale/translate'

const styles = fromModule(css)

export const BasketIcon: React.FC<{onClick?: () => void}> = ({onClick}) => {
	const {links} = useContext(GeneralContext)
	const basketCount = useBasketNumber()
	const t = useTranslation()

	return (
		<Link
			to={links.basket}
			onClick={onClick}
			title={t.basket.show}
			aria-label={t.basket.show}
			className={styles.basketicon()}
		>
			<Icon icon="shopping_cart" />
			{basketCount > 0 && (
				<i className={styles.basketicon.quantity()}>
					<span className={styles.basketicon.quantity.number()}>
						{basketCount}
					</span>
				</i>
			)}
		</Link>
	)
}
