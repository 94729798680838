import {Button} from 'layout/Button'
import {Container} from 'layout/Container'
import {GeneralContext} from 'types/general'
import {GetStaticProps} from 'next'
import Head from 'next/head'
import {Title} from 'layout/Title'
import css from './error.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useContext} from 'react'
import {useTranslation} from 'locale/translate'

const styles = fromModule(css)

export default function Custom500() {
	const {links} = useContext(GeneralContext) || {}
	const t = useTranslation()

	return (
		<div className={styles.error()}>
			<Head>
				<title>500 - Internal server error</title>
			</Head>
			<Container>
				<div className={styles.error.content()}>
					<Title.H1 mod="bold">500 - Internal server error</Title.H1>
					<p className={styles.error.content.text()}>
						Er ging iets fout bij het laden van de pagina. Probleem het later
						opnieuw, of contacteer ons als het probleem zich blijft voordoen.
					</p>
					<Button
						to={links?.home || '/'}
						className={styles.error.content.button()}
					>
						{t.error404.btnlabel}
					</Button>
				</div>
			</Container>
		</div>
	)
}

export const getStaticProps: GetStaticProps = async (context) => {
	const resGeneralNL = await fetch(
		process.env.NEXT_PUBLIC_DRUPAL_URL + `/api/general/nl`
	)
	const general: any = await resGeneralNL.json()

	return {
		props: {
			general
		},
		revalidate: 1
	}
}
