import { isBrowser } from "browser-or-node"
import React, { createContext, useContext, useEffect, useState } from 'react'
import { DPProduct } from 'types/DPTypes'
import { fromModule } from 'util/styler/Styler'
import css from './Basket.module.scss'
import { BasketModel } from './BasketModel'
import { ProductModel } from './ProductModel'

const styles = fromModule(css)

export type BasketContextType = {
	basket: BasketModel
	notification: BasketNotification
	storeBasket: (basket: BasketModel) => void
	addNotification: (notification: BasketNotification) => void
}

export type BasketNotification =
	{id: number, key: 'product_added', product: ProductModel}

const BasketContext = createContext<BasketContextType | false>(false)

export const BasketContextProvider: React.FC<{
	products: DPProduct[]
}> = ({products, children}) => {
	const [notification, addNotification] = useState<BasketNotification>(null)
	const [basket, setBasket] = useState(new BasketModel({products}, {updated_time: Date.now(), selected: {}}))

	useEffect(() => {
		if(localStorage.basket){
			setBasket(new BasketModel({products}, JSON.parse(localStorage.basket)))
		}
	}, [products])
	const storeBasket = (basket: BasketModel) => {
		setBasket(basket)
		localStorage.basket = JSON.stringify(basket.state)
	}

	return <BasketContext.Provider value={{basket, notification, storeBasket, addNotification}}>
		{children}
	</BasketContext.Provider>
}

export const useBasket = (): BasketContextType => {
	const result = useContext(BasketContext)
	if(!result) throw new Error("useBasket can only be used in a BasketContext")
	return result
}

export const useBasketNumber = (): number => {
	const [count, setCount] = useState<number>(0)
	useEffect(() => {
		setCount(retrieveBasketNumber())
		const interval = setInterval(() => setCount(retrieveBasketNumber()), 2000)
		return () => clearInterval(interval)
	}, [])
	return count
}

const retrieveBasketNumber = (): number => {
	if(!isBrowser) return 0
	if(!localStorage.basket) return 0
	return Object.keys(JSON.parse(localStorage.basket).selected).length
}