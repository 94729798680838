import {DPImage, DPLink, DPWysiwyg} from 'types/DPTypes'
import {Social, SocialLinkType} from 'layout/Social'

import {Container} from 'layout/Container'
import Image from 'next/image'
import {Link} from 'util/link'
import React from 'react'
import {Trusted} from 'layout/Trusted'
import css from './Copyright.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export type CopyrightTopType = {
	banner?: DPImage
	collaboration?: DPWysiwyg
	social?: Array<SocialLinkType>
}

export type CopyrightBottomType = {
	text?: DPWysiwyg
	links?: Array<DPLink>
}

export const Copyright: React.FC<CopyrightTopType & CopyrightBottomType> = ({
	banner,
	collaboration,
	social,
	text,
	links
}) => {
	return (
		<div className={styles.copyright()}>
			<Container mod="small">
				<CopyrightTop
					banner={banner}
					collaboration={collaboration}
					social={social}
				/>
				<span className={styles.copyright.line()} />
				<CopyrightBottom text={text} links={links} />
			</Container>
		</div>
	)
}

const CopyrightTop: React.FC<CopyrightTopType> = ({
	collaboration,
	banner,
	social
}) => {
	if (!collaboration && !banner && !social) return null
	return (
		<div className={styles.copyright_top()}>
			<div className={styles.copyright_row()}>
				<div className={styles.copyright_row.left()}>
					{collaboration && (
						<div className={styles.copyright_top.collaboration()}>
							<div className={styles.copyright_top.collaboration.image()}>
								<Image {...banner} />
							</div>
							<div className={styles.copyright_top.collaboration.text()}>
								<Trusted>{collaboration}</Trusted>
							</div>
						</div>
					)}
				</div>
				<div className={styles.copyright_row.right()}>
					<Social social={social} mod={['normal', 'light']} />
				</div>
			</div>
		</div>
	)
}

const CopyrightBottom: React.FC<CopyrightBottomType> = ({text, links}) => {
	if (!text && (!links || links.length === 0)) return null

	return (
		<div className={styles.copyright_bottom()}>
			<div className={styles.copyright_row()}>
				<div className={styles.copyright_row.left()}>
					{text && <Trusted>{text}</Trusted>}
				</div>
				<div className={styles.copyright_row.right()}>
					<CopyrightLinks links={links} />
				</div>
			</div>
		</div>
	)
}

const CopyrightLinks: React.FC<{
	links: Array<DPLink>
}> = ({links}) => {
	if (!links || links.length === 0) return null

	return (
		<div className={styles.copyright_links()}>
			{links.map((link) => (
				<div key={link.url} className={styles.copyright_links.item()}>
					<Link to={link.url} className={styles.copyright_links.item.link()}>
						{link.title}
					</Link>
				</div>
			))}
		</div>
	)
}
