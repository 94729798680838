import {useEffect, useRef} from 'react'

export const useAutohide = (disable = false) => {
	const ref = useRef<HTMLElement>()
	const height = useRef<number>(0)
	const lastScroll = useRef(0)
	const offset = useRef(0)

	useEffect(() => {
		const dom = ref!.current!
		const {style} = dom
		if (disable) {
			style.transform = null
			return
		}

		const scroll = () => {
			const y = window.pageYOffset
			const diff = lastScroll.current - y
			const last = offset.current
			offset.current += diff
			if (offset.current < -height.current) offset.current = -height.current
			if (y <= 0 || offset.current > 0) offset.current = 0
			if (last !== offset.current)
				style.transform = `translateY(${offset.current}px)`

			style.transition = null
			lastScroll.current = y
		}

		const trackHeight = () => {
			height.current = dom.offsetHeight
		}

		trackHeight()

		window.addEventListener('scroll', scroll)
		window.addEventListener('resize', trackHeight)

		return () => {
			window.removeEventListener('scroll', scroll)
			window.removeEventListener('resize', trackHeight)
		}
	}, [disable])

	return {ref}
}
