import React, {HTMLProps, useEffect, useRef} from 'react'

function isExternal(href: string) {
	if (href.startsWith && !href.startsWith('http')) return false
	return true
}

function isFile(href: string) {
	if (href.split('/').pop().includes('.')) return true
}

const click = (e) => {
	if (
		e.defaultPrevented ||
		e.button !== 0 ||
		e.altKey ||
		e.metaKey ||
		e.ctrlKey ||
		e.shiftKey ||
		e.currentTarget.target === '_blank' ||
		isExternal(e.currentTarget)
	)
		return
	e.preventDefault()
	const href = e.currentTarget.getAttribute('href')
}

export const Trusted: React.FC<
	{
		children: string
	} & HTMLProps<HTMLDivElement>
> = ({children, ...props}) => {
	const dom = useRef<HTMLDivElement>()
	useEffect(() => {
		const clear = []
		const listener = click.bind(null)
		dom.current.querySelectorAll('a').forEach((link) => {
			const href = link.getAttribute('href')
			if (href && (isExternal(href) || isFile(href)))
				link.setAttribute('target', '_blank')
			link.addEventListener('click', listener)
			clear.push(() => link.removeEventListener('click', listener))
		})
		return () => {
			clear.forEach((f) => f())
		}
	})
	return (
		<div
			{...props}
			ref={dom}
			dangerouslySetInnerHTML={{
				__html: Array.isArray(children) ? children[0] : children
			}}
		/>
	)
}
