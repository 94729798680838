import {DPHierarchy, DPLink, DPMainMenuTerm} from 'types/DPTypes'
import {GeneralContext, useMenuItemIsActive} from 'types/general'
import React, {useContext, useState} from 'react'

import {BasketIcon} from 'layout/BasketIcon'
import {Container} from 'layout/Container'
import {Icon} from 'layout/Icon'
import {Languages} from './Languages'
import {Link} from 'util/link'
import Logo from 'assets/logo.svg'
import {Mobilenav} from 'layout/app/Mobilenav'
import {Modal} from 'layout/Modal'
import css from './Header.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useAutohide} from 'util/autohide'
import {useMediaQuery} from 'util/mediaquery'
import {useTranslation} from 'locale/translate'

const styles = fromModule(css)

export const Header: React.FC<{
	home?: boolean
}> = ({home}) => {
	const {links} = useContext(GeneralContext)
	const t = useTranslation()

	const {ref} = useAutohide(false)
	const [isHovered, setHovered] = useState<boolean>(false)

	return (
		<>
			<span
				className={styles.headerbefore.mod({home}).is({hovered: isHovered})()}
			/>
			<header
				ref={ref}
				className={styles.header.mod({home}).is({hovered: isHovered})()}
			>
				<Container>
					<div className={styles.header.content()}>
						<div className={styles.header.left()}>
							<Link
								to={links.home}
								title={t.project_name}
								className={styles.header.left.logo()}
								aria-label={t.homepage.arialabel}
							>
								<Logo />
							</Link>
						</div>
						<div className={styles.header.right()}>
							<HeaderMobile />
							<HeaderDesktop setHovered={setHovered} />
						</div>
					</div>
				</Container>
			</header>
		</>
	)
}

const HeaderMobile: React.FC = () => {
	const {links} = useContext(GeneralContext)
	const isDesktop = useMediaQuery('(min-width: 1200px)')
	const [openMobilenav, setMobilenav] = useState<boolean>(false)
	const t = useTranslation()
	if (isDesktop) return null

	return (
		<>
			<div className={styles.header_mobile()}>
				<Link
					to={links.search}
					aria-label={t.search.arialabel}
					className={styles.header_mobile.search()}
				>
					<Icon icon="search" />
				</Link>
				<span className={styles.header_mobile.divider()} />
				<button
					onClick={() => setMobilenav(!openMobilenav)}
					aria-label={t.mobilemenu.open}
					className={styles.header_mobile.hamburger()}
				>
					<span className={styles.header_mobile.hamburger.bar()} />
					<span className={styles.header_mobile.hamburger.bar()} />
					<span className={styles.header_mobile.hamburger.bar()} />
				</button>
			</div>
			<Modal open={openMobilenav} onClose={() => setMobilenav(false)}>
				<Mobilenav onClose={() => setMobilenav(false)} />
			</Modal>
		</>
	)
}

const HeaderDesktop: React.FC<{setHovered?: (active: boolean) => void}> = ({
	setHovered
}) => {
	return (
		<div className={styles.header_desktop()}>
			<HeaderSubmenu />
			<HeaderMenu setHovered={setHovered} />
		</div>
	)
}

const HeaderSubmenu: React.FC = () => {
	const {secondary_menu} = useContext(GeneralContext)
	const {check} = useMenuItemIsActive()

	return (
		<nav className={styles.header_submenu()}>
			{secondary_menu.map((item, i) => {
				const active = check(item)
				return (
					<Link
						key={i}
						to={item.link?.url || '#'}
						className={styles.header_submenu.link.is({active})()}
					>
						{item.name}
					</Link>
				)
			})}
			<div className={styles.header_submenu.basket()}>
				<BasketIcon />
			</div>
			<Languages className={styles.header_submenu.languages()} />
		</nav>
	)
}

const HeaderMenu: React.FC<{setHovered?: (active: boolean) => void}> = ({
	setHovered
}) => {
	const {main_menu, links} = useContext(GeneralContext)
	const {check} = useMenuItemIsActive()
	const t = useTranslation()

	return (
		<nav className={styles.header_menu()}>
			{main_menu.map((item, i) => {
				const active = check(item)

				if (item?.children && item.children.length > 0) {
					return (
						<div
							key={i}
							className={styles.header_menu.dropdown.is({active})()}
							onBlur={() => setHovered(false)}
							onFocus={() => setHovered(true)}
							onMouseEnter={() => setHovered(true)}
							onMouseLeave={() => setHovered(false)}
						>
							<span className={styles.header_menu.dropdown.link()}>
								{item.name}
							</span>
							<div className={styles.header_menu.dropdown.content()}>
								<Container>
									<HeaderMegamenu categories={item?.children} />
								</Container>
							</div>
						</div>
					)
				}

				const isButton =
					item.link?.url === '/nl/word-lid' ||
					item.link?.url === '/en/membership'

				return (
					<Link
						key={i}
						to={item.link?.url}
						className={styles.header_menu.link
							.mod({btn: isButton})
							.is({active: active})()}
					>
						{item.name}
					</Link>
				)
			})}
			<Link
				to={links.search}
				title={t.search.arialabel}
				aria-label={t.search.arialabel}
				className={styles.header_menu.search()}
			>
				<Icon icon="search" />
			</Link>
		</nav>
	)
}

const HeaderMegamenu: React.FC<{
	categories: DPHierarchy<DPMainMenuTerm>
}> = ({categories}) => {
	if (!categories || categories.length === 0) return null

	return (
		<div className={styles.header_megamenu()}>
			<div className={styles.header_megamenu.row()}>
				{categories.map((category, i) => (
					<div key={i} className={styles.header_megamenu.row.col()}>
						<HeaderCategory {...category} />
					</div>
				))}
			</div>
		</div>
	)
}

const HeaderCategory: React.FC<{
	name: string
	link?: DPLink
	children: DPHierarchy<DPMainMenuTerm>
}> = ({name, link, children}) => {
	const {check} = useMenuItemIsActive()
	const item = {link, children}
	const active = check(item)

	return (
		<div className={styles.header_category()}>
			<h5 className={styles.header_category.title()}>
				<Link
					to={link?.url}
					className={styles.header_category.title.link.is({active})()}
				>
					{name}
				</Link>
			</h5>
			{children && children?.length > 0 && (
				<div className={styles.header_category.items()}>
					{children.map(
						(item, i) =>
							item.link?.url && (
								<div key={i} className={styles.header_category.items.item()}>
									<Link
										to={item.link.url}
										className={styles.header_category.items.item.link()}
									>
										{item.name}
									</Link>
								</div>
							)
					)}
				</div>
			)}
		</div>
	)
}
