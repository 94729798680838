import 'react-responsive-modal/styles.css'

import React, {useRef} from 'react'

import {Modal as ReactModal} from 'react-responsive-modal'
import css from './Modal.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

const overlayStyles = {
	display: 'none'
}

export const Modal: React.FC<{
	open: boolean
	onClose: () => void
}> = ({children, open, onClose}) => {
	const modalRef = useRef<HTMLDivElement>(null)

	return (
		<ReactModal
			center
			open={open}
			onClose={onClose}
			ref={modalRef}
			initialFocusRef={modalRef}
			showCloseIcon={false}
			classNames={{
				modal: styles.modal()
			}}
			styles={{
				overlay: overlayStyles
			}}
		>
			{children}
		</ReactModal>
	)
}
