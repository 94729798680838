import 'reflect-metadata';
import { DPProduct } from "types/DPTypes";
import { Memoize } from 'util/Memoize';
import { ProductModel, ProductState } from './ProductModel';

export type BasketState = {
	updated_time: number
	selected: Record<string, ProductState> 
}

export type BasketConfig = {
	products: DPProduct[]
}

export class BasketModel {

  constructor(readonly config: BasketConfig, readonly state: BasketState) { }

  @Memoize()
  get products(): ProductModel[] {
    return this.config.products.map(data => {
			const id = ProductModel.generateID(data.node_id)
			const state = this.state.selected[id] || {quantity: 0}
			return new ProductModel(this, state, data)
		})
  }

	@Memoize()
  get selectedProducts(): ProductModel[] {
    return this.products.filter(product => product.isSelected)
  }

	@Memoize()
  get price(): number {
    return this.selectedProducts.reduce((acc, prod) => acc + prod.price, 0)
  }

	@Memoize()
  get isEmpty(): boolean {
    return !this.products.find(prod => prod.isSelected)
  }

	@Memoize()
  get params(): string {
		const params = []
    this.selectedProducts.forEach(prod => {
			params.push(prod.ui.formassembly_availability)
			params.push(prod.ui.formassembly_quantity + '=' + prod.quantity)
		})
		return params.join('&')
  }

	// ----- Update state ----- //
	reset(): BasketModel {
		return new BasketModel(this.config, {
			updated_time: Date.now(),
			selected: {}
		})
	}

  updateProduct(product: ProductModel, newState: ProductState): BasketModel {
    return new BasketModel(this.config, {
			updated_time: Date.now(),
			selected: {
				...this.state.selected,
				[product.id]: newState
			}
		})
  }

	removeProduct(product: ProductModel): BasketModel {
		return new BasketModel(this.config, {
			updated_time: Date.now(),
			selected: Object.keys(this.state.selected).reduce((acc, id) => {
				return id === product.id ? acc : {...acc, [id]: this.state.selected[id]}
			}, {})
		})
	}
}
