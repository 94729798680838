import 'reflect-metadata';
import { DPProduct } from 'types/DPTypes';
import { Memoize } from 'util/Memoize';
import { BasketModel } from "./BasketModel";

export type ProductState = {
	quantity: number
}

export class ProductModel {

  constructor(readonly basket: BasketModel, readonly state: ProductState, readonly ui: DPProduct) { }

	public static generateID(nodeID: number): string {
		return `prod${nodeID}`
	}

	@Memoize()
  get id(): string {
    return ProductModel.generateID(this.ui.node_id)
  }

	@Memoize()
  get quantity(): number {
    return this.state.quantity
  }

	@Memoize()
  get price(): number {
    return this.state.quantity * this.ui.selling_price
  }

	@Memoize()
	get pricePretty(): string {
		return '€ ' + this.price.toFixed(2).toString().replace(/\./g, ',')
	}

	@Memoize()
  get isSelected(): boolean {
    return this.quantity > 0
  }

	// ----- Update state ----- //
	addToBasket(): BasketModel {
		if(this.isSelected) return this.basket
		return this.basket.updateProduct(this, {quantity: 1})
	}
	
	setQuantity(quantity: number): BasketModel {
		return this.basket.updateProduct(this, {quantity})
	}
	
	removeFromBasket(): BasketModel {
		if(!this.isSelected) return this.basket
		return this.basket.removeProduct(this)
	}
}
