import {GeneralContext, useMenuItemIsActive} from 'types/general'
import React, {useContext, useState} from 'react'

import AnimateHeight from 'react-animate-height'
import {BasketIcon} from 'layout/BasketIcon'
import {Button} from 'layout/Button'
import {Container} from 'layout/Container'
import {Icon} from 'layout/Icon'
import {Languages} from './Languages'
import {Link} from 'util/link'
import Logo from 'assets/logo.svg'
import css from './Mobilenav.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useTranslation} from 'locale/translate'

const styles = fromModule(css)

export const Mobilenav: React.FC<{onClose: () => void}> = ({onClose}) => {
	return (
		<nav className={styles.mobilenav()}>
			<Container>
				<MobilenavTop onClose={onClose} />
				<MobilenavMenu onClose={onClose} />
				<MobilenavSubmenu onClose={onClose} />
			</Container>
		</nav>
	)
}

const MobilenavTop: React.FC<{onClose: () => void}> = ({onClose}) => {
	const {links} = useContext(GeneralContext)
	const t = useTranslation()

	return (
		<div className={styles.top()}>
			<div className={styles.top.left()}>
				<Link
					to={links.home}
					onClick={onClose}
					className={styles.top.left.logo()}
				>
					<Logo />
				</Link>
			</div>
			<div className={styles.top.right()}>
				<Link
					to={links.search}
					onClick={onClose}
					className={styles.top.right.search()}
					aria-label={t.search.arialabel}
				>
					<Icon icon="search" />
				</Link>
				<span className={styles.top.right.divider()} />
				<button
					onClick={onClose}
					aria-label={t.mobilemenu.close}
					className={styles.top.right.hamburger()}
				>
					<span className={styles.top.right.hamburger.bar()} />
					<span className={styles.top.right.hamburger.bar()} />
				</button>
			</div>
		</div>
	)
}

const MobilenavMenu: React.FC<{onClose: () => void}> = ({onClose}) => {
	const {main_menu} = useContext(GeneralContext)
	const {check} = useMenuItemIsActive()
	const [activeDropdown, setDropdown] = useState<number | null>(null)

	return (
		<nav className={styles.menu()}>
			{main_menu.map((item, i) => {
				const active = check(item)

				if (item?.children && item.children.length > 0)
					return (
						<MobilenavDropdown
							{...item}
							onClose={onClose}
							onToggle={() =>
								activeDropdown === i ? setDropdown(null) : setDropdown(i)
							}
							activeDropdown={activeDropdown === i}
						/>
					)

				if (item.link?.url === '/nl/word-lid')
					return (
						<div key={i} className={styles.menu.item()}>
							<Button to={item.link?.url} onClick={onClose} mod="maxwidth">
								{item.name}
							</Button>
						</div>
					)

				return (
					<div key={i} className={styles.menu.item()}>
						<Link
							to={item.link?.url}
							onClick={onClose}
							className={styles.menu.item.link.is({active})()}
						>
							{item.name}
						</Link>
					</div>
				)
			})}
		</nav>
	)
}

const MobilenavDropdown: React.FC<{
	name: string
	children: any
	onClose: () => void
	onToggle: () => void
	activeDropdown?: boolean
}> = ({name, children, onClose, onToggle, activeDropdown}) => {
	return (
		<div className={styles.dropdown()}>
			<button
				onClick={onToggle}
				className={styles.dropdown.title.is({open: activeDropdown})()}
			>
				{name}
			</button>
			<AnimateHeight height={activeDropdown ? 'auto' : 0}>
				<div className={styles.dropdown.items()}>
					{children.map((child, i) => (
						<div key={i} className={styles.dropdown.items.item()}>
							<Link
								to={child.link?.url}
								onClick={onClose}
								className={styles.dropdown.items.item.link()}
							>
								{child.name}
							</Link>
						</div>
					))}
				</div>
			</AnimateHeight>
		</div>
	)
}

const MobilenavSubmenu: React.FC<{onClose: () => void}> = ({onClose}) => {
	const {secondary_menu} = useContext(GeneralContext)
	const {check} = useMenuItemIsActive()

	return (
		<div className={styles.submenu()}>
			<nav className={styles.submenu.items()}>
				{secondary_menu.map((item, i) => {
					const active = check(item)
					return (
						<Link
							key={i}
							to={item.link?.url}
							onClick={onClose}
							className={styles.submenu.items.link.is({active})()}
						>
							{item.name}
						</Link>
					)
				})}
			</nav>
			<BasketIcon onClick={onClose} />
			<Languages className={styles.submenu.languages()} />
		</div>
	)
}
